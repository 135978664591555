import React from "react";
import Helmet from "react-helmet";

const SEO = props => {
  return (
    <Helmet>
      <title>{props.content.title}</title>
      <meta name="description" content={props.content.excerpt} />
    </Helmet>
  );
};

export default SEO;
